import React from 'react';
import _ from 'lodash';
import { ActivityFeedItem as ActivityFeedItemType, ActivityItemStatuses, ReferenceLinkType } from 'data/activityFeed/types';
import ReferenceLink from './ReferenceLink';

export function describeActivity(activity: ActivityFeedItemType) {
  const eventBy = _.get(activity, 'eventBy.displayName');
  const databaseTitle = _.get(activity, 'references.database.title');
  const primaryFieldValue = _.get(activity, 'references.primaryFieldValue');
  if (activity.action === ActivityItemStatuses.CREATED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          created checklist for item
        <ReferenceLink references={activity.references} type={ReferenceLinkType.ITEM}>
          {primaryFieldValue}
        </ReferenceLink>
          in
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>
      </span>);
  }
  if (activity.action === ActivityItemStatuses.UPDATED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          updated database
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>,
          changed checklist for record
        <ReferenceLink references={activity.references} type={ReferenceLinkType.ITEM}>
          {primaryFieldValue}
        </ReferenceLink>
      </span>);
  }
  if (activity.action === ActivityItemStatuses.DELETED) {
    return (
      <span>
        <strong className={'bold'}>{eventBy} </strong>
          removed checklist for record
        <ReferenceLink references={activity.references} type={ReferenceLinkType.ITEM}>
          {primaryFieldValue}
        </ReferenceLink>
        inside database
        <ReferenceLink references={activity.references} type={ReferenceLinkType.DATABASE}>
          {databaseTitle}
        </ReferenceLink>
      </span>);
  }
  return 'activity';
}

export function describeActivityDetails(activity: ActivityFeedItemType) {
  return <span></span>;
}


export default {
  describeActivity,
  describeActivityDetails,
};
