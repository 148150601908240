import { CSSProperties } from '@material-ui/core/styles/withStyles';
import * as colors from 'styles/colors';

type ClassKey =
  'wrapper'
  | 'messageEditMode'
  | 'message'
  | 'username'
  | 'time'
  | 'messageText'
  | 'usernameWrapper'
  | 'usernameText'
  | 'menuWrapper'
  | 'messageUpdate'
  | 'updateMessageActionBtnContainer'
  | 'updateMessageActionBtn'
  | 'updatedMesssage'
  | 'contextMenuActive'
  | 'error'
  ;

export const styles: Record<ClassKey, CSSProperties> = {
  wrapper: {
    'padding': '18px 30px 14px',
    'display': 'flex',
    'position': 'relative',
    '&:hover': {
      backgroundColor: colors.patternsBlue,
    },
    '&:hover .message-context-menu': {
      display: 'block',
    },
    '@media only screen and (max-width: 450px)': {
      padding: '10px 30px',
    },
    '& i': {
      'font-style': 'italic',
    },
  },

  messageEditMode: {
    'backgroundColor': colors.patternsBlue,
    '& .DraftEditor-editorContainer': {
      backgroundColor: colors.white,
    },
    '& .DraftEditor-root': {
      border: `1px solid ${colors.muiFocusColor}`,
    },
  },

  contextMenuActive: {
    'backgroundColor': colors.patternsBlue,
    '& .message-context-menu': {
      display: 'block',
    },
  },

  menuWrapper: {
    position: 'absolute',
    top: 10,
    right: 15,
    display: 'none',
  },

  message: {
    padding: '0 10px',
    width: '100%',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
  },

  messageUpdate: {
    resize: 'none',
    width: '100%',
    height: '124px',
    boxSizing: 'border-box',
    padding: '10px',
    border: `1px solid ${colors.hubsyncBlue}`,
  },

  messageText: {
    lineHeight: '25px',
    whiteSpace: 'pre-line',
  },

  username: {
    display: 'flex',
    marginBottom: '4px',
  },

  updateMessageActionBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '14px',
  },

  updateMessageActionBtn: {
    marginRight: '10px',
  },

  usernameWrapper: {
    width: '8.2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  usernameText: {
    fontWeight: 500,
  },

  time: {
    opacity: .38,
    fontWeight: 400,
    paddingTop: '4px',
    marginLeft: '15px',
  },

  updatedMesssage: {
    marginTop: '-3px',
    cursor: 'default',
  },

  error: {
    fontSize: '12px',
    color: colors.redOrange,
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingTop: '10px',
  },
};
