import Fuse from 'fuse.js';

import * as types from 'data/activityFeed/types';
import { formatDate } from 'utilities/dates';

const fuse = new Fuse([] as types.ActivityFeedItem[], {
  keys: [
    'action',
    'model',
    'entity',
    'references.database.title',
    'references.database.type',
    'references.workspace.title',
    'references.title',
    'references.primaryFieldValue',
    'eventBy.displayName',
    'meta.text',
  ],
  threshold: 0.3,
});

function activitiesDescOrderCompare(
  event1: types.ActivityFeedItem,
  event2: types.ActivityFeedItem,
) {
  return event2.eventAt - event1.eventAt;
}

export function searchActivities(query: string): types.ActivityFeedItem[] {
  return fuse.search(query);
}

export function setCollectionItems(
  prevItems: types.ActivityFeedItem[],
  items: types.ActivityFeedItem[],
): void {
  fuse.setCollection([...prevItems, ...items]);
}

export function transformActivitiesResponse(
  activityFeedNode: types.ActivityFeedItem[],
): types.ActivityFeedGroup[] {
  const groups: { [groupDateStr: string]: types.ActivityFeedGroup } = {};
  const groupKeys: string[] = [];
  const activities = activityFeedNode.slice() || [];

  activities.sort(activitiesDescOrderCompare).forEach(activity => {
    const groupKey = formatDate(activity.eventAt, 'YYYYMMDD');
    let group = groups[groupKey];
    if (!group) {
      group = {
        eventDateStr: formatDate(activity.eventAt, 'MMMM D, YYYY'),
        activities: [],
      };
      groups[groupKey] = group;
      groupKeys.push(groupKey);
    }

    group.activities.push(activity);
  });

  return groupKeys.map(key => groups[key]);
}

export function filterActivitiesByType(
  type: string,
  items: types.ActivityFeedItem[],
) {
  const typeToFilterBy = type.toLowerCase() === 'item'
    ? 'task'
    : type.toLowerCase();

  return items.filter(item => {
    const model = item?.model?.toLowerCase();
    const references = item?.references;
    const databaseTypeDeep = references?.database?.type?.toLowerCase();
    const databaseType = references?.databaseType?.toLowerCase();

    return [model, databaseTypeDeep, databaseType].includes(typeToFilterBy);
  });
}
